/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import { Message } from 'element-ui'
import { apiConfig } from '@/utils/http/apiConfig'
import router from '../../router'
// import router from '@/router'
// import store from '@/store/index'

// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 15, // 响应超时时间
  apiType: 'base' // 自定义配置项，为了区分当前项目的接口还是第三方接口，默认为base(当前项目)
  // responseType: 'json',
  // headers: {
  //   'Content-Type': 'application/json;charset=utf-8'
  // }
})

/**
* 请求拦截器
* 请求发出之前，对请求做一些额外处理。
* 比如对不同api接口，携带不同的header参数，或者(也是最重要) 配置统一的token到header中
*/
instance.interceptors.request.use(
  config => {
    // 注意：需要自行处理浏览器跨域问题
    config.baseURL = apiConfig[`${config.apiType}ApiUrl`]
    if (config.apiType === 'base') { // 访问当前项目接口，可根据需要加上请求头等配置
      config.headers.token = getToken()
    } else { // 这里可以对第三方接口做请求配置
      config.baseURL = '/amap'
    }
    return config
  },
  error => {
    showTip(error)
    return Promise.reject(error)
  })

/**
* 响应拦截器
* 请求返回时，对返回的数据进行一些处理，如不同的状态对应的关系，比如约定状态码200为成功
*/
instance.interceptors.response.use(
  res => { // 请求成功，返回的状态码status以2开头
    const { data } = res
    if (res.status !== 200) {
      showTip('网络错误，请刷新重试！')
      return Promise.reject(data)
    }
    // 如果返回的状态码status为200，说明接口请求成功，可以正常拿到数据
    if (res.config.apiType === 'base') { // 访问当前项目接口
      // 目前与我们后端约定的code有三种状态：0、1、401，只有为1时才是接口调用成功
      if (data.code === 1) { // 调用成功
        return Promise.resolve(data.data)
      } else { // 返回异常，考虑到后面可能会增加code的状态，这里用errorHandle统一处理错误
        errorHandle(data)
        return Promise.reject(data)
      }
    } else { // 访问第三方接口，返回接口的所有数据到页面中自行处理逻辑
      return Promise.resolve(data)
    }
  },
  error => { // 请求失败，返回的状态码不是以2开头
    if (error.response.status === 401) {
      toLogin()
      return
    }
    showTip('网络错误，请刷新重试！')
    return Promise.reject(error)
  })

/**
* 接口返回异常后的错误统一处理(状态码status === 200 && code !== 1 时认为异常)
* @param {Object} data 请求错误的响应数据data
*/
const errorHandle = data => {
  switch (data.code) { // 状态码判断
    // 与后端约定 code = 401 表示未登录/登录过期
    case 401:
      toLogin()
      break
    case 7001:
      break
    default:
      showTip(data.msg)
  }
}

/**
* 未登录/登录过期，根据项目实际需求处理后续逻辑
*/
const toLogin = () => {
  showTip('登录已过期，请重新进行登录')
  localStorage.clear()
  router.replace({
    path: '/login'
  })
}

/**
* 错误提示
*/
const showTip = msg => {
  Message(msg)
}

/**
* 获取token，也可以放到store里面进行管理
*/
const getToken = () => {
  return localStorage.token
}

export default ({ url, method = 'POST', data }) => { // 这里默认post请求
  // 负责发请求：请求地址，请求方式，提交的数据
  return instance({
    url,
    method,
    // 1. 如果是get请求  需要使用params来传递submitData   ?a=10&c=10
    // 2. 如果不是get请求  需要使用data来传递submitData   请求体传参
    // [] 设置一个动态的key, 写js表达式，js表达式的执行结果当作KEY
    // method参数：get,Get,GET  转换成小写再来判断
    // 在对象，['params']:submitData ===== params:submitData 这样理解
    [method.toLowerCase() === 'get' ? 'params' : 'data']: data
  })
}
