import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'guidePage',
    component: () => import('../views/guidePage.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/home/index',
    component: () => import('../views/home.vue'),
    children: [
      {
        path: '/home/index',
        name: 'Index',
        meta: {
          keepAlive: true,
          isBack: false
        },
        component: () => import('../views/index.vue')
      },
      {
        path: '/home/orderFilling',
        name: 'orderFilling',
        component: () => import('../views/orderFilling.vue')
      },
      {
        path: '/home/orderSubmit',
        name: 'orderSubmit',
        component: () => import('../views/orderSubmit.vue')
      },
      {
        path: '/home/pay',
        name: 'pay',
        component: () => import('../views/pay.vue')
      },
      {
        path: '/home/detail',
        name: 'detail',
        component: () => import('../views/detail.vue')
      },
      {
        path: '/home/productEntry',
        name: 'productEntry',
        component: () => import('../views/productEntry.vue')
      },
      {
        path: '/home/orderDetail',
        name: 'orderDetail',
        component: () => import('../views/orderDetail.vue')
      },
      {
        path: '/home/drawback',
        name: 'drawback',
        component: () => import('../views/drawback.vue')
      }
    ]
  },
  {
    path: '/alipay',
    name: 'alipay',
    component: () => import('../views/alipay.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
