import $http from '@/utils/http/index'
export const getTips = data => { // 获取重要通知
  return $http({
    url: 'order/getTips',
    data
  })
}
export const register = data => { // 注册
  return $http({
    url: 'user/register',
    data
  })
}
export const login = data => { // 登录
  return $http({
    url: 'user/mobilelogin',
    data
  })
}
export const getIndexInfo = data => { // 获取首页列表
  return $http({
    url: 'order/index',
    data
  })
}
export const setmealList = data => { // 获取套餐列表
  return $http({
    url: 'order/setmealList',
    data
  })
}
export const submitOrder = data => { // 提交订单
  return $http({
    url: 'order/submitOrder',
    data,
    method: 'POST'
  })
}
export const getOrderDetail = data => { // 获取订单详情
  return $http({
    url: 'order/orderdetail',
    data,
    method: 'POST'
  })
}
export const getPayQrcode = data => { // 获取支付二维码
  return $http({
    url: 'order/getPayQrcode',
    data,
    method: 'POST'
  })
}
export const cancelOrder = data => { // 取消订单
  return $http({
    url: 'order/cancelOrder',
    data,
    method: 'POST'
  })
}
export const checkPayStatus = data => { // 检测支付状态
  return $http({
    url: 'order/checkPayStatus',
    data,
    method: 'POST'
  })
}
export const getWebInfo = data => { // 获取网页备案号
  return $http({
    url: 'order/getWebInfo',
    data,
    method: 'POST'
  })
}
export const sendEms = data => { // 发送邮箱验证码
  return $http({
    url: 'ems/send',
    data,
    method: 'POST'
  })
}
