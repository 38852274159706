import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import '@/assets/styles/iconfont.css'
import '@/assets/styles/index.scss'
import { Button, Icon, Steps, Step, Form, FormItem, Input, Pagination, Message, MessageBox } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from '@/modules/index.js'
// import store from './store'
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.use(Button).use(Icon).use(Steps).use(Step).use(Form).use(FormItem).use(Input).use(Pagination)
new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
